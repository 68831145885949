import React from 'react'
import CaseStudyCard from './CaseStudyCard'
import { Case_Studies } from '../constants/CaseStudies'

function CaseStudies() {
  return (
    <div className="xl:w-[1200px] xl:min-h-[1542.88px] md:w-[672px] md:max-h-fit md:mx-12 xl:mx-0 h-fit flex-col justify-start items-center xl:gap-10 md:gap-7 inline-flex xl:mt-16 px-4 md:px-0 w-full" id="CaseStudies">
    <div className="xl:h-[54.88px] md:h-[49px] flex-col justify-start items-start flex w-full">
        <div className="self-stretch xl:h-[54.88px] md:h-[49px] pb-[0.88px] flex-col justify-start items-start flex w-full">
            <div className="self-stretch text-stone-900 xl:text-4xl text-[28px] font-normal font-['Plus Jakarta Sans'] xl:leading-[54px] leading-[48.75px] w-full text-left">Case Studies</div>
        </div>
    </div>
    <div className="xl:w-[1248px] md:w-[672px] w-full justify-center items-start xl:gap-8 md:gap-6 inline-flex">
        <div className="grow shrink basis-0 min-h-[456px] h-fit xl:justify-center items-start flex md:flex-wrap md:justify-between md:flex-row flex-col pt-5 md:pt-0 gap-7 md:gap-0">
            {Case_Studies.map(({cover, title, desc, link})=><CaseStudyCard cover={cover} title={title} desc={desc} link={link} key={link}/>)}
        </div>
    </div>
</div>
  )
}

export default CaseStudies