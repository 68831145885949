import React from 'react'
import DottedBg from './DottedBg'

function HeroSection() {
  return (
    <>
    <div className='xl:pt-[88px] md:pt-[52.75px] pt-[60px] md:mx-12 xl:mx-0 mx-4'>
        <div className="xl:w-[1200px] md:w-[672px] text-stone-900 xl:text-4xl font-normal font-['Plus Jakarta Sans'] xl:leading-[54px] leading-10 text-[28px] mt-5">I’m a product designer focused on building products that empower users and drive business success.</div>
        <div className="xl:w-[1200px] md:w-[672px] text-neutral-500 text-base xl:text-xl font-normal font-['Plus Jakarta Sans'] leading-normal xl:leading-[25px] my-4">Product Design Intern @Unscript.ai | IIITM Gwalior'25 | B.Tech | MBA | India</div>
    </div>
    <DottedBg/>
    </>
  )
}

export default HeroSection