import './App.css';
import CaseStudies from './components/CaseStudies';
import Collab from './components/Collab';
import HeroSection from './components/HeroSection';
// import InternshipProjects from './components/InternshipProjects';
import NavBar from './components/NavBar';
import WorkExperience from './components/WorkExperience';

function App() {
  return (
    <div className="xl:max-w-[1200px] md:max-w-[768px] mx-auto pb-20">
      <NavBar/>
      <HeroSection/>
      {/* <InternshipProjects/> */}
      <CaseStudies/>
      <WorkExperience/>
      <Collab/>
    </div>
  );
}

export default App;
