import React from 'react'
import { redirectURLs } from '../constants/redirectURLs'
import hamBurger from "../assets/hamBurger.svg"
import SideBar from './SideBar'
import { useDispatch } from "react-redux"
import { bindActionCreators } from 'redux'
import { actions } from "../store/index"
import { Link } from 'react-router-dom'

function NavBar() {
    const dispatch = useDispatch();
    const action = bindActionCreators(actions, dispatch);

    return (
      <>
        <SideBar />
        <div className="w-full px-4 xl:w-[1296px] md:w-[768px] md:px-12 xl:h-[88px] md:h-[52.75px] flex-col justify-start items-start inline-flex fixed z-50 xl:-ml-12 bg-white">
          <div className="self-stretch md:h-[52.75px] xl:h-[88px] md:py-2.5 xl:py-5 bg-white justify-between items-center inline-flex py-[14px]">
            <div className="xl:w-[340px] md:w-[672px] flex-col justify-center items-start inline-flex">
              <div className="flex-col justify-start items-start flex">
                <div className="self-stretch h-[32.75px] flex-col justify-start items-start flex">
                  <Link to="https://www.sahilmittal.studio/" className="py-[0.38px] justify-start items-start inline-flex">
                    <div className="text-neutral-700 text-lg xl:text-[25px] font-normal font-['Plus Jakarta Sans'] underline leading-loose">sahil mittal</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="h-[19.50px] justify-end items-start gap-7 xl:flex hidden">
              <div className="justify-center items-center flex">
                <div className="justify-center items-center flex">
                  <div className="flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-[19.50px] flex-col justify-start items-start flex">
                      <div className="py-[0.75px] justify-start items-start inline-flex">
                        <a className="text-stone-900 text-base font-normal font-['Plus Jakarta Sans'] leading-[17.50px] hover-underline-animation" href='#CaseStudies'>Case Studies</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-center items-center flex">
                <div className="justify-center items-center flex">
                  <div className="flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-[19.50px] flex-col justify-start items-start flex">
                      <div className="py-[0.75px] justify-start items-start inline-flex">
                        <a className="text-stone-900 text-base font-normal font-['Plus Jakarta Sans'] leading-[17.50px] hover-underline-animation" href="#Experience">Experience</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-center items-center flex">
                <div className="flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-[19.50px] flex-col justify-start items-start flex">
                    <div className="py-[0.75px] justify-start items-start inline-flex">
                      <a className="text-stone-900 text-base font-normal font-['Plus Jakarta Sans'] leading-[17.50px] hover-underline-animation hover:cursor-pointer" href={redirectURLs.resume} target='_blank' rel="noreferrer">Resume</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-center items-center flex">
                <div className="flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-[19.50px] flex-col justify-start items-start flex">
                    <div className="py-[0.75px] justify-start items-start inline-flex">
                      <a href='mailto:sahilm0704@gmail.com' className="text-stone-900 text-base font-normal font-['Plus Jakarta Sans'] leading-[17.50px] hover-underline-animation">Hire Me</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-8 h-8 p-2.5 bg-zinc-100 rounded-2xl justify-center items-center gap-2.5 md:inline-flex xl:hidden" onClick={() => action.toggleSideBar()}>
              <img src={hamBurger} className="w-4 h-4 relative" alt="icon" />
            </div>
          </div>
        </div>
      </>
    )
}

export default NavBar
