import RecipeCup from "../assets/Recipe_Cup.svg"
import FoundIt from "../assets/FoundIt.svg"
import GrocerEase from "../assets/GrocerEase.svg"
import EcoTrack from "../assets/Eco_Track.svg"
import Nivaara from "../assets/Nivaara Post.svg"
import IRCTC from "../assets/IRCTC.svg"
import StudentsClub from "../assets/Students_Club.svg"


export const Case_Studies = [
    {
        title:"Product Design Internship Project: Unscript",
        desc:"Building Unscript’s User Dashboard — Product Design Case Study",
        link:"https://medium.com/@sahil-mittal/bf27eca76498"
    },
    {
        cover:RecipeCup,
        title:"Product Teardown S8 by Product Space",
        desc:"Recipe Cup Product Teardown",
        link:"https://www.figma.com/deck/RZ2hSfwHQ3zlKVGqdoTpLJ/Recipe-Cup-Product-Teardown?node-id=1-26&viewport=296%2C-103%2C0.75&t=tSv4AL4UdgvFNAkG-1&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1"
    },
{
    cover:GrocerEase,
    title:"Personal Project",
    desc:"GrocerEase— Groceries management got easier",
    link:"https://www.behance.net/gallery/203388149/GrocerEase-Groceries-management-got-easier"
},
{
    cover:EcoTrack,
    title:"Product Games 3.0",
    desc:"EcoTrack — A Comprehensive Monitoring Solution for Sustainable Household Management",
    link:"https://medium.com/@sahil-mittal/ecotrack-a-comprehensive-monitoring-solution-for-sustainable-household-management-8071abb2a986"
},
{
    cover:Nivaara,
    title:"Salesforce Design Days’23",
    desc:"Nivaara— Bridging Healthcare Knowledge Gaps in Rural India",
    link:"https://medium.com/@sahil-mittal/nivaara-bridging-healthcare-knowledge-gaps-in-rural-india-505a72a3dca7"
},
{
    cover:IRCTC,
    title:"Personal Project",
    desc:"Case Study: IRCTC App Train Selecting Screen Redesign",
    link:"https://bootcamp.uxdesign.cc/irctc-app-train-selecting-screen-redesign-e03fb45f4fd5"
},
{
    cover:StudentsClub,
    title:"AASF IIITM Gwalior",
    desc:"Case Study: Students’ Club App",
    link:"https://medium.com/@sahil-mittal/students-club-app-case-study-c5a7dc011252"
},
{
    cover:FoundIt,
    title:"UI/UX design contest organized by Zuno",
    desc:"Case Study: Foundit Landing Page Redesign",
    link:"https://bootcamp.uxdesign.cc/case-study-foundit-landing-page-redesign-6ef59c5d5d8d"
}
]