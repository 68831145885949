const rootReducer = (state = false, action) => {

    switch (action.type) {
        case "toggleSideBar":
            return {
                isSidebarVisible: !state.isSidebarVisible
            };
        default:
            return state;
    }
};

export default rootReducer;