import React from 'react'
import { redirectURLs } from '../constants/redirectURLs'
import CollabLink from './CollabLink'

function Collab() {
  return (
    <div className="xl:w-[1200px] md:w-[672px] md:mx-12 xl:mx-0 md:mt-12 xl:mt-0 xl:h-[126.12px] md:h-[107.62px] bg-white flex-col justify-start items-start xl:gap-10 md:gap-6 gap-5 inline-flex px-4 w-full md:px-0">
    <div className="self-stretch xl:h-[54.88px] md:h-[49.88px] pb-[0.88px] flex-col justify-start items-start flex">
        <div className="self-stretch text-stone-900 xl:text-4xl text-[28px] font-normal font-['Plus Jakarta Sans'] xl:leading-[54px] leading-[48.75px]">Let’s collaborate</div>
    </div>
    <div className="xl:h-[31.25px] md:h-fit pb-px justify-start items-center xl:gap-12 gap-4 inline-flex flex-wrap">
        <CollabLink link={redirectURLs.linkedIn} platform={"LinkedIn"}/>
        <CollabLink link={redirectURLs.dribbble} platform={"Dribbble"}/>
        <CollabLink link={redirectURLs.medium} platform={"Medium"}/>
        <CollabLink link={redirectURLs.resume} platform={"Resume"}/>
        <CollabLink link={redirectURLs.email} platform={"Email"}/>
    </div>
</div>
  )
}

export default Collab