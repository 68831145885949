import React from 'react'
import heroBg from "../assets/heroBg.svg"
import heroBg2 from "../assets/heroBg2.svg"
import heroBg3 from "../assets/heroBg3.svg"
function DottedBg() {
  return (
    <div className="xl:w-[1200px] md:w-[672px] md:h-[261px] flex-col justify-start items-start inline-flex md:mx-12 xl:mx-0 pb-5 xl:pb-0 w-full px-4 md:px-0">
    <div className="xl:w-[1200px] md:w-[672px] md:h-[241px] justify-center items-center inline-flex w-full">
        <div className="xl:w-[1200px] md:w-[672px] h-[241px] relative w-full">
            <img src={heroBg} alt="" className='h-full xl:block  object-cover hidden w-full'/>
            <img src={heroBg2} alt="" className='h-full sm:object-cover w-full hidden sm:block xl:hidden'/>
            <img src={heroBg3} alt="" className='h-full block w-full object-cover sm:hidden'/>
        </div>
    </div>
</div>
  )
}

export default DottedBg