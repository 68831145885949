import React from 'react'
import { Link } from 'react-router-dom'

function CaseStudyCard({cover, title, desc, link}) {

    if(cover)
    return (
    <Link className="wrapper caseStudyCardWrapper xl:p-6 md:w-1/2 w-full xl:hover:border xl:hover:shadow-xl xl:hover:rounded-2xl xl:hover:cursor-pointer md:mt-4 xl:mt-0" to={link}>
    <div className="grow shrink basis-0 xl:h-[456px] md:min-h-[289px] md:max-h-fit flex-col justify-start items-center xl:gap-5 md:gap-4 inline-flex mt-2 caseStudyCard w-full gap-4">
                <div className="xl:w-[584px] md:w-[328px] w-full xl:rounded-xl md:rounded-md justify-center items-center inline-flex">
                    <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start inline-flex w-full">
                        <img className="xl:w-[584px] md:w-[328px] xl:h-[340px] md:h-[190.96px] w-full relative rounded-xl" alt={title} src={cover} />
                    </div>
                </div>
                <div className="self-stretch xl:h-[92px] md:min-h-[81.6px] md:max-h-fit flex-col justify-center items-start flex">
                    <div className="self-stretch xl:h-[92px] md:min-h-[81.6px] md:max-h-fit flex-col justify-start items-start gap-[3.38px] flex">
                        <div className="self-stretch h-[25px] flex-col justify-start items-start flex">
                            <div className="self-stretch text-neutral-500 xl:text-base text-sm font-normal font-['Plus Jakarta Sans'] leading-[25px]">{title}</div>
                        </div>
                        <div className="self-stretch xl:h-[63.62px] md:min-h-[56.62px] md:max-h-fit pb-[0.62px] flex-col justify-start items-start flex">
                            <div className="self-stretch text-stone-900 xl:text-xl text-lg font-normal font-['Plus Jakarta Sans'] xl:leading-loose leading-7">{desc}<br/></div>
                        </div>
                    </div>
                </div>
            </div>
            </Link>
  )

  else
  return(
    <Link className="wrapper caseStudyCardWrapper xl:p-6 md:w-1/2 w-full xl:hover:border xl:hover:shadow-xl xl:hover:rounded-2xl xl:hover:cursor-pointer md:mt-4 xl:mt-0" to={link}>
    <div className="grow shrink basis-0 xl:h-[456px] md:min-h-[289px] md:max-h-fit flex-col justify-start items-center xl:gap-5 md:gap-4 inline-flex mt-2 caseStudyCard w-full gap-4">
                <div className="bg-[#F0F0EE] xl:w-[584px] md:w-[328px] w-full xl:rounded-xl md:rounded-md justify-center items-center inline-flex rounded-xl">
                    <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start inline-flex w-full rounded-xl">
                    <div className="xl:w-[584px] md:w-[328px] xl:h-[340px] md:h-[190.96px] h-[289px] w-full relative rounded-xl flex flex-col justify-center gap-2">
                    <div className="self-stretch text-center text-black xl:text-2xl md:text-xl font-semibold font-['Plus Jakarta Sans']">Coming Super Soon…</div>
<div className="self-stretch text-center text-neutral-500 xl:text-xl md:text-sm font-normal font-['Plus Jakarta Sans']">Wrapping up designs for leadership review. <br/>You can have a look at the draft</div>
                    </div>
                    </div>
                </div>
                <div className="self-stretch xl:h-[92px] md:min-h-[81.6px] md:max-h-fit flex-col justify-center items-start flex">
                    <div className="self-stretch xl:h-[92px] md:min-h-[81.6px] md:max-h-fit flex-col justify-start items-start gap-[3.38px] flex">
                        <div className="self-stretch h-[25px] flex-col justify-start items-start flex">
                            <div className="self-stretch text-neutral-500 xl:text-base text-sm font-normal font-['Plus Jakarta Sans'] leading-[25px]">{title}</div>
                        </div>
                        <div className="self-stretch xl:h-[63.62px] md:min-h-[56.62px] md:max-h-fit pb-[0.62px] flex-col justify-start items-start flex">
                            <div className="self-stretch text-stone-900 xl:text-xl text-lg font-normal font-['Plus Jakarta Sans'] xl:leading-loose leading-7">{desc}<br/></div>
                        </div>
                    </div>
                </div>
            </div>
            </Link>
  )
}

export default CaseStudyCard