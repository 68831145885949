import React, { useEffect, useState } from 'react'
import cross from "../assets/cross.svg"
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../store/index'

function SideBar() {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const sideBarVisiblity = useSelector(state => state.isSidebarVisible);
  const [visible, setVisible] = useState(sideBarVisiblity);
  const [animate, setAnimate] = useState(sideBarVisiblity);

  useEffect(() => {
    if (sideBarVisiblity) {
      setVisible(true);
      setAnimate(true);
    } else {
      setAnimate(false);
      setTimeout(() => setVisible(false), 200); // Match the animation duration
    }
  }, [sideBarVisiblity]);

  if (!visible) return null;

  return (
    <div className={`fixed top-0 bottom-0 right-0 w-[50vw] flex z-[500] py-5 flex-col items-center bg-white shadow-lg transition-transform ${animate ? 'animate-slideIn' : 'animate-slideOut'}`}>
      <div className='flex flex-col gap-8 items-center'>
        <a href="#CaseStudies" className='text-base leading-[17.50px]'>Case Studies</a>
        <a href="#Experience" className='text-base leading-[17.50px]'>Experience</a>
        <a href="Sahil_Mittal_UX_Resume.pdf" target="_blank" rel="noreferrer" className='text-base leading-[17.50px]'>Resume</a>
        <a href="/" className='text-base leading-[17.50px]'>Hire Me</a>
      </div>
      <div className="w-8 h-8 p-2.5 bg-zinc-100 rounded-2xl justify-center items-center gap-2.5 inline-flex absolute bottom-5" onClick={() => action.toggleSideBar()}>
        <img src={cross} alt="Close" className='h-5 w-5' />
      </div>
    </div>
  );
}

export default SideBar;
