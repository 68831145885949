import React from 'react'
import { Link } from 'react-router-dom'

function WorkExCard({timeline, link, role, company, desc}) {
  return (
    <div className="xl:w-[1200px] md:w-[672px] justify-center items-center xl:gap-10 inline-flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex md:gap-2 xl:gap-0">
            <div className="self-stretch justify-start items-start inline-flex">
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-[17px] flex-col justify-start items-start flex">
                        <div className="text-neutral-500 xl:text-base md:text-sm font-normal font-['Plus Jakarta Sans'] uppercase leading-none">{timeline}</div>
                    </div>
                    <div className="self-stretch xl:h-[51.69px] md:h-[30.69px] pb-[0.69px] flex-col justify-start items-start flex">
                        <div className="self-stretch text-stone-900 xl:text-2xl text-xl font-normal font-['Plus Jakarta Sans'] leading-[30px] xl:leading-[50.40px]">{role}, {company}</div>
                    </div>
                </div>
                <div className="flex-col justify-start items-start xl:inline-flex hidden">
                    <div className="self-stretch justify-start items-start inline-flex">
                        <div className="py-[0.38px] justify-start items-start flex">
                            <Link className="text-stone-900 text-base font-normal font-['Plus Jakarta Sans'] underline leading-loose" to={link}>Visit link</Link>
                        </div>
                        <div className="text-stone-900 text-base font-normal font-['Plus Jakarta Sans'] leading-loose">→</div>
                    </div>
                </div>
            </div>
            <div className="self-stretch xl:h-[84px] md:h-fit gap-4 xl:gap-0 flex-col justify-start items-start flex">
                <div className="self-stretch text-neutral-500 xl:text-base text-sm font-normal font-['Plus Jakarta Sans'] xl:leading-7 leading-snug">{desc}</div>
                <div className="self-stretch justify-start items-start inline-flex xl:hidden">
                        <div className="py-[0.38px] justify-start items-start flex">
                            <Link className="text-stone-900 text-base font-normal font-['Plus Jakarta Sans'] underline leading-loose" to={link}>Visit link</Link>
                        </div>
                        <div className="text-stone-900 text-base font-normal font-['Plus Jakarta Sans'] leading-loose">→</div>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default WorkExCard