import React from 'react'
import { Link } from 'react-router-dom'

function CollabLink({link, platform}) {
  return (
    <Link to={link} className="flex-col justify-start items-start inline-flex" target={platform === 'Resume' ? '_blank' : '_self'}>
    <div className="self-stretch justify-start items-start inline-flex">
        <div className="py-[0.38px] justify-start items-start flex">
            <div className="text-stone-900 xl:text-xl text-lg font-normal font-['Plus Jakarta Sans'] underline leading-loose">{platform}</div>
        </div>
        <div className="text-stone-900 xl:text-xl text-lg font-normal font-['Plus Jakarta Sans'] leading-loose">→</div>
    </div>
</Link>
  )
}

export default CollabLink