import React from 'react'
import WorkExCard from './WorkExCard'

function WorkExperience() {
  return (
    <div className="xl:w-[1200px] md:w-[672px] md:mx-12 mx-4 xl:mx-0  h-fit md:h-[412.26px] flex-col justify-start items-center xl:gap-10 md:gap-6 inline-flex xl:my-16 md:my-12 my-10 gap-5" id="Experience">
    <div className="xl:h-[54.88px] md:h-[49.88px] flex-col justify-start items-start flex w-full">
        <div className="self-stretch xl:h-[54.88px] md:h-[49.88px] pb-[0.88px] flex-col justify-start items-start flex w-full">
            <div className="self-stretch text-stone-900 xl:text-4xl text-[28px] font-normal font-['Plus Jakarta Sans'] xl:leading-[54px] leading-[48.75px] w-full">Work Experience</div>
        </div>
    </div>
    <WorkExCard timeline={"July 2023 - Present"} role={"Product Design Intern"} company={"Unscript"} desc={"Developed a user-friendly dashboard for AI avatar creation and bulk video generation, designed a teleprompter for easy recording, and created a video editor with AI-based text-to-speech.\n Collaborating with cross-functional teams (product, engineering, business), I conducted a usability study with 10 users and revamped the company logo and landing page for a modern look, enhancing user experience and driving innovation in digital product development."} link={"https://unscript.ai"}/>
    
    <WorkExCard timeline={"June 2023 - July 2023"} role={"Associate Design Intern"} company={"ScaleNow Technosolutions"} desc={"Designed a Content Management System enabling seamless multi-project content management for the team. Additionally, developed websites for Midas Group, the company’s first client, as well as for First Choice Group and Toockies India."} link={"https://www.scalenowtech.com/"}/>
</div>
  )
}

export default WorkExperience